@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Serif+Pro:wght@400;700&display=swap');

.App, .row, #root, html, body {
    height: 100%;
    margin-top: 0.3em;
    font-family: 'Roboto', sans-serif;
}

html {
    height: 99%;
}

.mainContent {
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    text-align: center;
}

.headerName {
    width: 100%;
}

/* small media */
@media (max-width: 768px) {
    .readerContainer {
        width: 100%;
    }

    .postListContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        transition: .3s ease-out;
        background: white;
    }

    .postListContainer.inScope {
        transform: translate(0);
    }
}

@media (min-width: 768px) {
    .postListContainer, .readerContainer {
        height: 100%;
        overflow-x: auto;
    }

    .postListContainer {
        width: 350px;
    }

    .readerContainer {
        width: calc(100% - 350px);
        padding-left: 20px;
    }

    .showPostListBtn {
        visibility: hidden;
    }
}

@media (prefers-color-scheme: dark) {
    html, #root {
        background-color: #222;
        color: #bbb;
    }

    .postListContainer {
        background: #222;
    }
}
