.postButton {
    font-size: 100%;
    font-family: inherit;
    border: 0;
    text-align: inherit;
    background-color: inherit;
    width: 100%;
    height: 100%;
    padding: 0;
}

.postButton:hover > span.unselectedName:after, .postButton > span.selectedName:after {
    content: " ←";
}

.postList {
    height: 100%;
    overflow-x: auto;
}

.postListUl {
    list-style-type: none;
    padding-right: 10px;
    padding-left: 10px;
}

.postListItem {
    min-height: 4em;
    display: grid;
}

.postListItem:not(:last-child) {
    border-bottom: 2px solid lightgray;
}

@media (prefers-color-scheme: dark) {
    .postListItem:not(:last-child) {
        border-bottom: 2px solid darkgray;
    }

    .postButton, .btn.showPostListBtn {
        color: #bbb;
    }
}

.clear-search {
    margin-top: 0.3em;
    width: 100%;
}

* {
    box-sizing: border-box;
}
