@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Serif+Pro:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App, .row, #root, html, body {
    height: 100%;
    margin-top: 0.3em;
    font-family: 'Roboto', sans-serif;
}

html {
    height: 99%;
}

.mainContent {
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    text-align: center;
}

.headerName {
    width: 100%;
}

/* small media */
@media (max-width: 768px) {
    .readerContainer {
        width: 100%;
    }

    .postListContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        transition: .3s ease-out;
        background: white;
    }

    .postListContainer.inScope {
        transform: translate(0);
    }
}

@media (min-width: 768px) {
    .postListContainer, .readerContainer {
        height: 100%;
        overflow-x: auto;
    }

    .postListContainer {
        width: 350px;
    }

    .readerContainer {
        width: calc(100% - 350px);
        padding-left: 20px;
    }

    .showPostListBtn {
        visibility: hidden;
    }
}

@media (prefers-color-scheme: dark) {
    html, #root {
        background-color: #222;
        color: #bbb;
    }

    .postListContainer {
        background: #222;
    }
}

.postButton {
    font-size: 100%;
    font-family: inherit;
    border: 0;
    text-align: inherit;
    background-color: inherit;
    width: 100%;
    height: 100%;
    padding: 0;
}

.postButton:hover > span.unselectedName:after, .postButton > span.selectedName:after {
    content: " ←";
}

.postList {
    height: 100%;
    overflow-x: auto;
}

.postListUl {
    list-style-type: none;
    padding-right: 10px;
    padding-left: 10px;
}

.postListItem {
    min-height: 4em;
    display: grid;
}

.postListItem:not(:last-child) {
    border-bottom: 2px solid lightgray;
}

@media (prefers-color-scheme: dark) {
    .postListItem:not(:last-child) {
        border-bottom: 2px solid darkgray;
    }

    .postButton, .btn.showPostListBtn {
        color: #bbb;
    }
}

.clear-search {
    margin-top: 0.3em;
    width: 100%;
}

* {
    box-sizing: border-box;
}

.reader {
    max-width: 500px;
    font-family: 'Source Serif Pro', serif;
}

.reader p > img:only-child {
    display: block;
}

.reader img[moz-reader-center] {
    margin-left: auto;
    margin-right: auto;
}

.reader * {
    max-width: 100%;
    height: auto;
}

.btn-spaced {
    margin: auto 0.5em;
}

.identityButtonOuter {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
}

div.alerts {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    z-index: 10;
}

