.reader {
    max-width: 500px;
    font-family: 'Source Serif Pro', serif;
}

.reader p > img:only-child {
    display: block;
}

.reader img[moz-reader-center] {
    margin-left: auto;
    margin-right: auto;
}

.reader * {
    max-width: 100%;
    height: auto;
}

.btn-spaced {
    margin: auto 0.5em;
}
